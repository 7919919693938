import { title, primaryColor } from "assets/jss/material-kit-react.jsx";

const projectOrderStyle = {
  section: {
    background: primaryColor,
    borderRadius: 20,
    padding: "70px 40px",
    textAlign: "center"
  },
  title: {
    ...title,
    textAlign: 'left',
    marginBottom: "3rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "white"
  }
};

export default projectOrderStyle;
