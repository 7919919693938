import React, { useState } from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Carousel from "react-slick"

// @material-ui/icons
import LandscapeIcon from '@material-ui/icons/Landscape';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import CropLandscapeIcon from '@material-ui/icons/CropLandscape';

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"

import threeDVisualOne from "assets/img/3dVisuals/3d-visual-1.jpeg"
import threeDVisualTwo from "assets/img/3dVisuals/3d-visual-2.jpeg"
import threeDVisualThree from "assets/img/3dVisuals/3d-visual-3.jpeg"
import planThreeD from "assets/img/3dVisuals/plan3d.png"
import shoppingList from "assets/img/3dVisuals/shopping-list.jpeg"
import projectOrderStyle from "assets/jss/material-kit-react/views/landingPageSections/projectOrderStyle.jsx"
import NavPills from "../../../components/NavPills/NavPills"
import { infoColor } from "../../../assets/jss/material-kit-react"

const plans = [
  {
    id: "3dViews",
    title: "Visuels 3D",
    icon: LandscapeIcon,
    images: [threeDVisualOne, threeDVisualTwo, threeDVisualThree]
  },
  {
    id: "3dPlan",
    title: "Plan 3D",
    icon: CropLandscapeIcon,
    images: [planThreeD]
  },
  // {
  //   id: "360Exploring",
  //   title: "Visite 360°",
  //   icon: ThreeDRotationIcon
  // },
  {
    id: "shoppingList",
    title: "Liste Shopping",
    icon: LocalMallIcon,
    images: [shoppingList]
  },
]


const ProjectOrderSection = (props) => {
  const { classes } = props
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="left">
        <GridItem xs={12} sm={12} md={8}>
          <h3 className={classes.title}>En commandant un projet, vous recevrez :</h3>
        </GridItem>
      </GridContainer>
      <div>
        <NavPills
          color="info"
          horizontal={{
            tabsGrid: { xs: 12, sm: 4, md: 4 },
            contentGrid: { xs: 12, sm: 8, md: 8 }
          }}
          tabs={plans.map(({images = [], title, icon}) => ({
            tabButton: title,
            tabIcon: icon,
            tabContent: (
              <div>
                <Carousel {...settings}>
                  {images.map((image, i) => (
                    <div>
                      <img
                        src={image}
                        alt={`${title}-image-${i}`}
                        className="slick-image"
                        style={{maxHeight: 400}}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            )
          }))}
        />
      </div>
    </div>
  )
}

export default withStyles(projectOrderStyle)(ProjectOrderSection)
