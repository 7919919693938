import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// React icons
import { FaPlay } from 'react-icons/fa';

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// Sections for this page
import ProductSection from "./Sections/ProductSection.jsx";
import TeamSection from "./Sections/TeamSection.jsx";
import WorkSection from "./Sections/WorkSection.jsx";
import AchievementsSection from "./Sections/AchievementsSection"
import ServiceOfQualitySection from "./Sections/ServiceOfQualitySection"
import HowItWorksSection from "./Sections/HowItWorksSection"
import ProjectOrderSection from "./Sections/ProjectOrderSection"
import PricingSection from "./Sections/PricingSection"

const dashboardRoutes = [];

class LandingPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="ELM Home"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/landing-bg.jpeg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <h1 className={classes.title}>Votre histoire commence avec nous.</h1>
                <h4>
                  Votre intérieur est le reflet de votre personnalité, la première impression doit être la bonne.
                </h4>
                {/*<br />*/}
                {/*<Button*/}
                {/*  color="danger"*/}
                {/*  size="lg"*/}
                {/*  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"*/}
                {/*  target="_blank"*/}
                {/*  rel="noopener noreferrer"*/}
                {/*>*/}
                {/*  <FaPlay/>*/}
                {/*  Watch video*/}
                {/*</Button>*/}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {/*un service de qualité*/}
            <ServiceOfQualitySection/>
            {/*<AchievementsSection/>*/}
            <HowItWorksSection/>
            <ProjectOrderSection/>
            <PricingSection/>
            {/*faq*/}
            <TeamSection />
            <WorkSection />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
