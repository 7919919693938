import React, { useState } from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import Close from "@material-ui/icons/Close"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import pricingStyle from "assets/jss/material-kit-react/views/landingPageSections/pricingStyle.jsx"
import OfferCard from "../../../components/OfferCard"
import CoachingImg from "assets/img/offers/coaching.png"
import DrawingImg from "assets/img/offers/drawing.png"
import ProImg from "assets/img/offers/pro.png"
import Button from "../../../components/CustomButtons/Button"


function Transition(props) {
  return <Slide direction="down" {...props} />
}

const PricingSection = (props) => {
  const { classes } = props
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const handleOpenModal = () => {
    setIsVisibleModal(true)
  }
  const handleCloseModal = () => {
    setIsVisibleModal(false)
  }

  return (
    <div className={classes.section} id="offers">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Nos offres</h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer alignItems="center" justify="center" rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
          <GridItem xs={12} sm={12} md={4}>
            <OfferCard
              title="Coaching"
              image={CoachingImg}
              items={[
                "Conseil en décoration",
                "Conseil en aménagement",
                "Conseil par téléphone",
                "Planche d'ambiance: +50€",
                "Liste shopping: +60€",
              ]}
              price={"99€ /h"}
              handleSelectThisOffer={handleOpenModal}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <OfferCard
              title="Projet Déco"
              image={DrawingImg}
              items={[
                "Une pièce simple: salon, chambre, bureau, entrée, dressing",
                "Visuels 3D haute définition",
                "Plan 3D",
                // "Visite 360°",
                "Planche d'ambiance",
                "Liste shopping",
                "Planche matières & couleurs",
                "Une pièce technique (salle de bain, cuisine): + 50€",
                "Une pièce multi fonction (pièce de vie, suite parentale, ...): + 100€",
              ]}
              price={"Dès 600€"}
              handleSelectThisOffer={handleOpenModal}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <OfferCard
              title="Pour les pros"
              image={ProImg}
              items={[
                "Restaurant, café, boutique, chambre d'hôtel, hall d'entrée, ....",
                "Visuels 3D haute définitions",
                "Plan 3D",
                // "Visite 360°",
                "Planche d'ambiance",
                "Liste shopping",
                "Planche matières & couleurs",
              ]}
              price={"Sur devis"}
              handleSelectThisOffer={handleOpenModal}
            />
          </GridItem>
        </GridContainer>
      </div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={isVisibleModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseModal}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Contact</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>
            Me contacter au <b>0668617238</b> ou remplir le formulaire de contact au bas de la page d'accueil
          </p>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={handleCloseModal}
            color="transparent"
            simple
          >
            Fermer
          </Button>
          {/*<Button*/}
          {/*  onClick={handleCloseModal}*/}
          {/*  color="danger"*/}
          {/*  simple*/}
          {/*>*/}
          {/*  Fermer*/}
          {/*</Button>*/}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default withStyles(pricingStyle)(PricingSection)
