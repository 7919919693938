import React, { useState } from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// nodejs library that concatenates classes
import classNames from "classnames"
import CheckCircle from "@material-ui/icons/CheckCircle"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

import OfferCardStyle from "../../assets/jss/material-kit-react/components/OfferCardStyle"
import Card from "../Card/Card"
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart"
import Button from "../CustomButtons/Button"

const OfferCard = ({ ...props }) => {
  const { classes, title, price, image, items, handleSelectThisOffer } = props

  return (
    <Card className={classes.container}>
      <div className={classes.topWrapper}>
        <div className={classes.imageContainer}>
          <img
            alt={`${title} icon`}
            src={image}
            className={
              classes.imgRaised +
              " " +
              classes.imgRounded +
              " " +
              classes.imgFluid +
              " " +
              classes.image
            }
          />
        </div>
        <div className={classes.titleWrapper}>
          <h4 className={classes.title}>
            {title}
          </h4>
        </div>
        <div className={classes.itemsContainer}>
          {items.map(item => (
            <div className={classes.itemWrapper}>
              <div className={classes.iconWrapper}>
                <CheckCircle fontSize={"small"} />
              </div>
              <p className={classes.item}>{item}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.bottomCardWrapper}>
        <h4 className={classes.price}>
          {price}
        </h4>
        <Button color="info" round onClick={handleSelectThisOffer}>
          Je choisis cette offre <AddShoppingCartIcon className={classes.buttonIcon} />
        </Button>
      </div>
    </Card>
  )
}

OfferCard.defaultProps = {
  items: [],
}

OfferCard.propTypes = {
  classes: PropTypes.object.isRequired,
  showcaseImage: PropTypes.node.isRequired,
  handleSelectThisOffer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default withStyles(OfferCardStyle)(OfferCard)
