import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons
import Hearing from "@material-ui/icons/Hearing"
import Brush from "@material-ui/icons/Brush"
import Public from "@material-ui/icons/Public"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx"

const ServiceOfQualitySection = (props) => {
  const { classes } = props
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Un service de qualité</h2>
          <h5 className={classes.description}>
            Notre priorité est de rendre votre intérieur agréable et fonctionnel. Grâce à nous, vous aurez la sensation
            d’avoir un espace unique et à votre image. Votre satisfaction étant notre priorité, nous vous
            accompagnerons avant, pendant et après la réalisation pour que tout soit parfait.
            3 points en gage de qualité :
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Écoute"
              description="Nous sommes présents pour receuillir vos envies, vos impératifs et vos contraintes afin de vous proposer le rendu idéal!"
              icon={Hearing}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Créativité"
              description="Si vous n’avez pas d’idée ou bien au contraire, si vous en débordez, pas de panique, nous sommes là pour vous aider afin de vous proposer une décoration unique!"
              icon={Brush}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Ouverture d'esprit"
              description="Nous n’avons pas d’idée pré-construite, chaque proposition que vous nous ferez sera prise en compte pour le projet final, dans la limite du réalisable et dans une volonté de cohérence esthétique."
              icon={Public}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

export default withStyles(productStyle)(ServiceOfQualitySection)
