import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title,
} from "assets/jss/material-kit-react.jsx"

const OfferCardStyle = {
  container: {
    minHeight: 560,
    marginBottom: 0,
    paddingBottom: 0
  },
  topWrapper: {
    padding: 20,
    flex: 1,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
  },
  image: {
    maxWidth: 200
  },
  itemsContainer: {
    padding: '20px 0'
  },
  itemWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  item: {
    textAlign: 'left'
  },
  buttonIcon: {
    marginLeft: 6,
  },
  iconWrapper: {
    marginTop: 2,
    marginRight: 6,
    color: infoColor
  },
  bottomCardWrapper: {
    background: primaryColor,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    padding: '10px 0'
  },
  title: {
    ...title,
    fontSize: 22,
  },
  price: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white'
  }
}

export default OfferCardStyle
